



























import { Component, Prop, Vue, Watch, Mixins } from 'vue-property-decorator';
import ability from '../ability';

@Component({})
export default class AirQuality extends Vue {
  public $store: any;
  public loading: boolean = false;
  public areas: any = [];

  public beforeCreate() {
    if (this.$store.state.buildings.length === 0) {
      // no building or area available, we just push a message.
      this.areas = [];
      this.loading = false;
      return;
    }
    if (ability.can('read', 'data-building')) {
      // the user can read building data, so let's go to the buildings dashboard
      this.$router.push({name: 'airquality-buildings'});
    } else {
      // not possible, so go to a list of areas
      this.areas = this.$store.getters.getAreas();
      if (this.areas.length === 1) {
        const area = this.areas[0];
        // only one area, go directly to the first one
        this.$router.push({
          name: 'airquality-area',
          params: {
            areaId: area.id,
          },
        });
      } else {
        this.$router.push({name: 'airquality-areas'});
      }
    }
  }

}
